<template>
  <section class="data-page">
    <header class="page-head">
      <h2>极侠数据处理服务 - 全能版</h2>
      <h2>兑换券</h2>
    </header>
    <main class="page-info">
      说明：<br />
      1、本券可直接兑换一份极侠数据处理服务（全能版）；<br />
      2、同一账号仅能激活一份服务；<br />
      3、请在有效期内使用，过期不补；<br />
      4、数据处理服务介绍：<br />
    </main>
    <div class="page-table">
      <img src="./assets/table.png" alt="" srcset="" />
    </div>
    <div class="page-detail">
      <div class="detail-title">服务说明</div>
      <div class="detail-item">
        <h5 class="detail-subTitle">平面快拼图</h5>
        <div class="detail-content">
          <p>
            使用高度自动化的飞行、数据传输方案，配合优化的数据处理算法，可以迅速获取测区可量测二维拼接图，无需携带笨重的笔记本电脑，一部手机，搞定所有事情。
          </p>
          <p>
            以200亩面积为例，从测绘到出图时间大概22分钟，其中处理时间约10分钟，上传速度约500KB/s，上传文件大小约50MB。
          </p>
        </div>
      </div>
      <div class="detail-item">
        <h5 class="detail-subTitle">平面高清图</h5>
        <div class="detail-content">
          灵活而强大的平面高清图测绘方案，用户可以根据现场环境和自己意图灵活调整飞行参数，获取合适的高清图数据。
        </div>
      </div>
      <div class="detail-item">
        <h5 class="detail-subTitle">三维高清图</h5>
        <div class="detail-content">
          针对果树、茶园等山地场景，通过优化的飞行和数据解算方案，生成测区高精度三维数据，直接用于植保作业。
        </div>
      </div>
      <div class="detail-item">
        <h5 class="detail-subTitle">平原障碍物识别</h5>
        <div class="detail-content">
          针对平原版会员，使用障碍物检测算法，在高清图中检测出电线杆、树等障碍物，直接导入障碍图层，用于规划植保航线，减少手动圈障碍物的工作量，提升作业效率和飞行安全性。
        </div>
      </div>
    </div>
    <div class="page-ft">
      <div v-if="isSuccess" class="page-btn">已成功激活服务</div>
      <div v-else class="page-btn" @click="onSubmit">激活服务</div>
    </div>
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
export default {
  config: {
    title: '服务详情'
  },
  data() {
    return {
      isLoading: false,
      isSuccess: false
    }
  },
  mixins: [basePage],
  methods: {
    async onSubmit() {
      if (this.isLoading) return
      this.isLoading = true
      try {
        await this.$_submitDataWidthUi({
          url: '/api/xservice/coupon/active',
          data: this.$route.query
        })
        this.$toast.success({ message: '激活成功' })
        this.isSuccess = true
      } catch (error) {
        window.console.log('激活错误', error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.data-page {
  background: #010101 url(./assets/data-service-bg.png) no-repeat top;
  background-size: cover;
  color: #fff;
  min-width: 320px;
}
.page-head {
  padding-top: 16px;
  padding-top: 4.26vw;
  text-align: center;
  h2 {
    font-size: 24px;
    font-size: 6.4vw;
    line-height: 1.33;
  }
}
.page-info {
  padding: 12px 13px;
  font-size: 14px;
  line-height: 1.45;
}
.page-table {
  margin-top: 6px;
  margin-left: 16px;
  margin-right: 14px;
  img {
    display: block;
    width: 100%;
  }
}
.page-detail {
  margin-top: 18px;
  margin: 18px 13px 0px 13px;
  padding: 18px 14px 2px;
  background-color: rgba(23, 23, 23, 1);
  .detail-title {
    margin-bottom: 5px;
    height: 30px;
    font-size: 15px;
    line-height: 30px;
  }
  .detail-item {
    margin-bottom: 12px;
  }
  .detail-subTitle {
    position: relative;
    height: 17px;
    font-size: 12px;
    margin-left: 10px;
    line-height: 17px;
    color: #fefffe;
    &::after {
      content: ' ';
      position: absolute;
      left: -10px;
      top: 50%;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      transform: translateY(-50%);
      background-color: #b87f4a;
    }
  }
  .detail-content {
    margin-top: 7px;
    font-size: 12px;
    line-height: 1.42;
    color: #fefffe;
  }
}
.page-ft {
  padding: 30px 0;
}
.page-btn {
  margin: 0 auto;
  text-align: center;
  width: 59.466vw;
  max-width: 60%;
  min-width: 6em;
  line-height: 42px;
  font-size: 16px;
  color: #fff;
  border-radius: 21px;
  background: #c82f31;
  background: linear-gradient(to right, #d8a067, #af7641);
  box-shadow: 2px 3px 18px 0px rgba(0, 0, 0, 0.25);
}
@media screen and(min-width: 640px) {
  .page-head {
    padding-top: 45px;
    h2 {
      font-size: 30px;
    }
  }
  .page-info {
    padding-top: 54px;
    line-height: 1.7;
  }
  .page-table {
    margin-top: 30px;
  }
  .page-detail {
    margin-top: 30px;
    .detail-item {
      margin-bottom: 24px;
    }
    .detail-subTitle,
    .detail-content {
      font-size: 14px;
    }
    .detail-content {
      margin-top: 14px;
    }
  }
}
</style>
